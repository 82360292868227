export const LINKS = [
  {
    to: '/',
    sv: 'IT-rekrytering',
    en: 'IT Recruitment',
  },
  {
    to: '/talent-programme',
    target: '',
    sv: 'Talangprogram',
    en: 'Talent programmes',
  },
  {
    to: '/developer',
    target: '',
    sv: 'Jobba som utvecklare',
    en: 'Work in the IT industry',
  },
  {
    to: '/jobs',
    target: '_blank',
    sv: 'Jobbannonser',
    en: 'Job ads',
  },
  {
    to: '/contact',
    target: '',
    sv: 'Kontakta våra IT-rekryterare',
    en: 'Contact our IT recruiters',
  },
];

export const QUICK_LINKS = {
  en: 'Quick links',
  sv: 'Snabblänkar',
};
export const GET_IN_TOUCH = {
  en: 'Get in touch',
  sv: 'Här finns vi',
};
export const GOTHENBURG = {
  en: 'Gothenburg, Sweden',
  sv: 'Göteborg, Sverige',
};
export const ALL_RIGHTS = {
  en: '© 2021 SoftwareSkills.se. All rights reserved.',
  sv: '© 2021 SoftwareSkills.se. Alla rättigheter förbehållna.',
};
export const PRIVACY_POLICY = {
  en: 'Privacy policy',
  sv: 'Integritetspolicy',
};
