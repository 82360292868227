import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { Editor, LINK_REGEX } from './constants';
import { Stepper } from 'react-form-stepper';
import { fileTypes } from './constants';
import {
  faPlusCircle,
  faAngleDown,
  faAngleUp,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { emailRegex, nameRegex } from '../../utils';
import { countries } from './constants';
import defaultAvatars from '../../assets/avatar';
import { useActions, getBase64, getBase64FromUrl } from '../../utils';
import {
  Button,
  Input,
  FileInput,
  Note,
  FormLabel,
  Container,
  colors,
  Avatar,
  ToggleCollapsableSection,
  CollapsableSection,
  CollapsableSectionForm,
  TitleAndIcon,
  DefaultAvatars,
  Icon,
  OtherInfoContainer,
  DropDownInput,
  RadioInput,
  Checkbox,
  RangeInput,
  FormFieldContainer,
} from 'components';
import * as actions from './actions';
import { toast } from 'react-toastify';
import * as _ from 'underscore';

export default props => {
  const steps = props.quickQuestions.length
    ? [
        { title: 'Personal Info' },
        { title: 'Quick Questions' },
        { title: 'Upload CV' },
        { title: 'Upload Image' },
        { title: 'Other Info' },
      ]
    : [
        { title: 'Personal Info' },
        { title: 'Upload CV' },
        { title: 'Upload Image' },
        { title: 'Other Info' },
      ];
  const defaultAvatar = require('../../assets/placeholder-avatar.png');
  const defaultProfile = {
    id: '',
    name: '',
    email: '',
    city: '',
    country: '',
    cv: null,
    linkedIn: '',
    avatar: { body: defaultAvatar },
    message: '',
  };
  const projectFields = {
    isOpen: true,
    employer: '',
    name: '',
    description: '',
    startedAt: '',
    endedAt: '',
  };
  const educationFields = {
    isOpen: true,
    degree: '',
    university: '',
    city: '',
    startedAt: '',
    endedAt: '',
  };
  const [activeStep, setActiveStep] = useState(0);
  const [showAvatars, setShowAvatars] = useState(false);
  const [showCvPopUp, setShowCVPopUP] = useState(false);
  const { updateApplication } = useActions(actions);
  const [error, setError] = useState('');
  const [educationErrors, setEducationError] = useState([]);
  const [projectErrors, setProjectErrors] = useState([]);
  const [quickQuestions, setQuickQuestions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [educations, setEducations] = useState([]);
  const [profile, setProfile] = useState(defaultProfile);
  const [nextDisable, setNextDisable] = useState(false);
  const { applyJobError, updateResponse, job } = useSelector(
    state => state.job,
  );

  let searchQuery = window.location.search;
  let source = 'homepage';
  if (searchQuery) {
    source = searchQuery.split('?source=')[1] || 'homepage';
  }
  useEffect(() => {
    if (updateResponse && updateResponse.candidate && !profile.id) {
      setProfile({ ...profile, id: updateResponse.candidate._id });
      updateApplication({
        _handler: 'update-application',
        reactApplyFlow: true,
        company: job.company,
        _id: profile.id,
        email: profile.email,
        type: 'setGDPR',
      });
      toast.success('Saved Successfully!');
    }
  }, [updateResponse, profile]);

  useEffect(() => {
    if (applyJobError) {
      toast.error('Something went wrong! Try Again');
    }
  }, [applyJobError]);

  const clearEntries = () => {
    setActiveStep(0);
    setProfile(defaultProfile);
    setEducations([]);
    setProjects([]);
    setError('');
  };

  useEffect(() => {
    setQuickQuestions(props.quickQuestions);
  }, [props.quickQuestions]);

  const handleOnClickStepper = step => {
    //do nothing
  };

  const renderPersonalInfoForm = () => {
    return (
      <FormFieldContainer>
        <FormFieldContainer padding>
          <FormLabel>
            Name<sup>*</sup>
          </FormLabel>
        </FormFieldContainer>
        <FormFieldContainer>
          <Input
            full
            marginBottom
            value={profile.name}
            onChange={e => setProfile({ ...profile, name: e.target.value })}
          />
        </FormFieldContainer>
        <FormFieldContainer padding>
          <FormLabel>
            Email<sup>*</sup>
          </FormLabel>
        </FormFieldContainer>
        <FormFieldContainer>
          <Input
            full
            value={profile.email}
            onChange={e => setProfile({ ...profile, email: e.target.value })}
          />
        </FormFieldContainer>
        <Note>We'll never share your email with anyone else.</Note>
        <FormFieldContainer padding>
          <FormLabel>
            {' '}
            City<sup>*</sup>
          </FormLabel>
        </FormFieldContainer>
        <FormFieldContainer>
          <Input
            full
            marginBottom
            value={profile.city}
            onChange={e => setProfile({ ...profile, city: e.target.value })}
          />
        </FormFieldContainer>
        <FormFieldContainer padding>
          <FormLabel>
            {' '}
            Country<sup>*</sup>
          </FormLabel>
        </FormFieldContainer>
        <FormFieldContainer>
          <DropDownInput
            options={countries}
            placeholder="Please select your country"
            value={profile.country}
            onChange={e => setProfile({ ...profile, country: e.target.value })}
          />
        </FormFieldContainer>
        <Note error>{error}</Note>
      </FormFieldContainer>
    );
  };

  const renderQuestionInput = (question, index) => {
    if (question.mimeType === 'textfield')
      return (
        <FormFieldContainer key={`textfield_${index}`}>
          <Input
            full
            marginBottom
            value={question.value}
            onChange={e =>
              setQuickQuestions(
                quickQuestions.map((question, i) =>
                  i === index
                    ? { ...question, value: e.target.value }
                    : question,
                ),
              )
            }
          />
        </FormFieldContainer>
      );
    else if (question.mimeType === 'radio')
      return (
        <Container key={`radio_${index}`} noPadding marginLeft column>
          <RadioInput
            options={question.field_options.map(option => {
              return option.option_title;
            })}
            name={`b${index}`}
            onChange={e =>
              setQuickQuestions(
                quickQuestions.map((question, i) =>
                  i === index
                    ? { ...question, value: e.target.value }
                    : question,
                ),
              )
            }
            value={question.value}
          />
        </Container>
      );
    else if (question.mimeType === 'dropdown')
      return (
        <FormFieldContainer key={`dropdown_${index}`}>
          <DropDownInput
            options={question.field_options.map(option => {
              return option.option_title;
            })}
            placeholder="select your answer"
            value={question.value}
            onChange={e =>
              setQuickQuestions(
                quickQuestions.map((question, i) =>
                  i === index
                    ? { ...question, value: e.target.value }
                    : question,
                ),
              )
            }
          />
        </FormFieldContainer>
      );
    else if (question.mimeType === 'slider')
      return (
        <FormFieldContainer key={`slider_${index}`}>
          <FormFieldContainer>
            <FormLabel>{question.minRange}</FormLabel>
            <RangeInput
              value={question.value}
              min={question.minRange}
              max={question.maxRange}
              onChange={e =>
                setQuickQuestions(
                  quickQuestions.map((question, i) =>
                    i === index
                      ? { ...question, value: e.target.value }
                      : question,
                  ),
                )
              }
            />
            <FormLabel>{question.maxRange}</FormLabel>
          </FormFieldContainer>
          <FormLabel>value: {question.value}</FormLabel>
        </FormFieldContainer>
      );
    else if (question.mimeType === 'checkbox')
      return (
        <FormFieldContainer key={`checkbox_${index}`}>
          {question.field_options.map(option => {
            return [
              <Checkbox
                label={option.option_title}
                onChange={() => {
                  if (question.value.includes(option.option_title)) {
                    let array = JSON.parse(JSON.stringify(question.value));
                    array.splice(array.indexOf(option.option_title), 1);
                    setQuickQuestions(
                      quickQuestions.map((question, i) =>
                        i === index ? { ...question, value: array } : question,
                      ),
                    );
                  } else {
                    let array =
                      JSON.parse(JSON.stringify(question.value)) || [];
                    array.push(option.option_title);
                    setQuickQuestions(
                      quickQuestions.map((question, i) =>
                        i === index ? { ...question, value: array } : question,
                      ),
                    );
                  }
                }}
              />,
            ];
          })}
        </FormFieldContainer>
      );
    else if (question.mimeType === 'textarea')
      return (
        <FormFieldContainer key={`textarea_${index}`}>
          <ReactQuill
            value={question.value ? question.value : '<p></p>'}
            onChange={value =>
              setQuickQuestions(
                quickQuestions.map((question, i) =>
                  i === index ? { ...question, value } : question,
                ),
              )
            }
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
          />
        </FormFieldContainer>
      );
  };

  const renderQuickQuestions = () => {
    return (
      <FormFieldContainer>
        {quickQuestions.map((question, index) => {
          return [
            <FormFieldContainer key={`a${index}`} noPadding marginTop>
              <FormLabel>
                {question.title}
                {question.isRequired ? <sup>*</sup> : null}
              </FormLabel>
            </FormFieldContainer>,
            renderQuestionInput(question, index),
          ];
        })}
        <Note error>{error}</Note>
      </FormFieldContainer>
    );
  };

  const renderCVConfirmationPopUp = () => {
    return (
      <Modal isOpen={showCvPopUp}>
        <ModalHeader backdrop={false}>Confirm</ModalHeader>
        <ModalBody>Please attach a cv if you can!</ModalBody>
        <ModalFooter>
          <Button
            marginRight
            border
            primary
            onClick={() => setShowCVPopUP(false)}
          >
            Ok
          </Button>
          <Button
            noMargin
            border
            primary
            onClick={() => {
              setProfile({ ...profile, cv: null });
              setError('');
              setActiveStep(activeStep + 1);
              setShowCVPopUP(false);
            }}
          >
            {' '}
            Skip Anyway{' '}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const removeCV = (index, cvs) => {
    let totalPayloadSize = 0;
    cvs.splice(index, 1);
    if (!cvs.length) setProfile({ ...profile, cv: null });
    else setProfile({ ...profile, cv: cvs });
    cvs.map(cv => {
      totalPayloadSize += cv.size;
    });
    if (totalPayloadSize > 8000000) {
      setNextDisable(true);
      setError('Total file size should not be grateer than 8MB.');
    } else {
      setNextDisable(false);
      setError('');
    }
  };

  const renderUploadCVForm = () => {
    return [
      <FormFieldContainer>
        {renderCVConfirmationPopUp()}
        <FormFieldContainer>
          <FormFieldContainer padding>
            <FormLabel>LinkedIn URL</FormLabel>
          </FormFieldContainer>
          <Input
            full
            marginBottom
            value={profile.linkedIn}
            onChange={e => setProfile({ ...profile, linkedIn: e.target.value })}
          />
        </FormFieldContainer>
        <Container noPadding>
          <TitleAndIcon>Upload CV</TitleAndIcon>
        </Container>
        <Note marginTop noMargin>
          Upload a Image, PDF or Doc file
        </Note>
        <Container noPadding noMargin>
          <FileInput
            type="file"
            marginBottom
            onChange={async e => {
              let totalPayloadSize = 0;
              const files = Array.from(e.target.files);
              if (!files.length) return;
              let cvArray = profile.cv ? [...profile.cv] : [];
              await Promise.all(
                files.map(async file => {
                  let cvExists = _.find(cvArray, cv => {
                    if (cv.name === file.name && cv.size === file.size)
                      return true;
                  });
                  if (cvExists) return toast.error('File is already selected.');
                  else if (
                    file.type.indexOf(fileTypes.pdf) === 0 ||
                    file.type.indexOf(fileTypes.doc) === 0 ||
                    file.type.indexOf(fileTypes.docx) === 0 ||
                    file.type.indexOf('image') === 0
                  ) {
                    let cvObject = {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                    };
                    cvObject.body = await getBase64(file);
                    cvArray.push(cvObject);
                    if (profile.cv && profile.cv.length) {
                      cvArray = _.union(cvArray, profile.cv);
                    }
                    cvArray.map(cv => {
                      totalPayloadSize += cv.size;
                    });
                    if (totalPayloadSize > 8000000) {
                      setNextDisable(true);
                    } else {
                      setNextDisable(false);
                    }
                    setError('');
                  } else {
                    setError('Please upload pdf, doc or image file.');
                  }
                }),
              );
              setProfile({ ...profile, cv: cvArray });
            }}
            multiple
          />
        </Container>
      </FormFieldContainer>,
      profile.cv && profile.cv.length ? (
        <Container key={12}>
          <FormLabel right key={1.2}>
            Selected File : <br />
            {profile.cv.map((file, index) => {
              return (
                <span key={index}>
                  {file.name}{' '}
                  {file.size
                    ? `(${Math.ceil((file.size / 1000000) * 100) / 100} MB)`
                    : null}{' '}
                  <Icon
                    icon={faWindowClose}
                    onClick={() => removeCV(index, profile.cv)}
                  />
                  <br />
                </span>
              );
            })}
          </FormLabel>
        </Container>
      ) : null,
      <Note error key={1}>
        {error}{' '}
        {nextDisable
          ? 'Total file size limit exceeded. Files should be less than 8MB.'
          : null}
      </Note>,
      profile.cv && profile.cv.length > 1 ? (
        <Container marginTop noPadding key={23}>
          {profile.cv ? (
            <Button
              key={22}
              right
              noMargin
              border
              danger
              onClick={() => {
                setProfile({ ...profile, cv: null });
                setError('');
              }}
            >
              {' '}
              Remove all files{' '}
            </Button>
          ) : null}
        </Container>
      ) : null,
      <Button
        style={{ float: 'right' }}
        border
        primary
        key={5464}
        onClick={() => {
          setProfile({ ...profile, cv: null });
          setError('');
          setActiveStep(activeStep + 1);
        }}
      >
        Skip
      </Button>,
    ];
  };

  const renderUploadImageForm = () => {
    return [
      <Container key={0} noPadding marginTop>
        <Avatar image={profile.avatar.body} />
        <FileInput
          type="file"
          padding
          onChange={async e => {
            if (e.target.files[0].type.indexOf('image') === 0) {
              let avatarObj = {
                name: e.target.files[0].name,
                size: e.target.files[0].size,
                type: e.target.files[0].type,
              };
              avatarObj.body = await getBase64(e.target.files[0]);
              setProfile({
                ...profile,
                avatar: avatarObj,
              });
              setError('');
            } else {
              setError('Please select an image');
            }
          }}
          full
          marginBottom
        />
      </Container>,
      <Container key={1} noPadding width={`100%`}>
        <ToggleCollapsableSection onClick={() => setShowAvatars(!showAvatars)}>
          <Container noPadding width={`100%`}>
            <span style={{ color: 'white' }}>View Default Avatars</span>
            <Icon
              color={'white'}
              icon={showAvatars ? faAngleUp : faAngleDown}
            />
          </Container>
        </ToggleCollapsableSection>
      </Container>,
      <Container key={2} noPadding>
        <CollapsableSection show={showAvatars}>
          {defaultAvatars.map((url, index) => {
            return (
              <DefaultAvatars
                key={index}
                src={url}
                onClick={async () => {
                  let avatarObj = {
                    name: `avatar.${url.indexOf('jpg') !== -1 ? 'jpg' : 'png'}`,
                    type:
                      url.indexOf('jpg') !== -1
                        ? fileTypes.jpgImage
                        : fileTypes.pngImage,
                  };
                  avatarObj.body = await getBase64FromUrl(url);
                  setProfile({ ...profile, avatar: avatarObj });
                }}
              />
            );
          })}
        </CollapsableSection>
      </Container>,
      <Note error key={3}>
        {error}
      </Note>,
      <Container key={4} marginTop noPadding>
        {profile.avatar.body !== defaultAvatar ? (
          <Button
            border
            danger
            onClick={() => {
              setError('');
              setProfile({ ...profile, avatar: { body: defaultAvatar } });
            }}
          >
            {' '}
            Remove Avatar{' '}
          </Button>
        ) : (
          <Button
            border
            primary
            onClick={() => {
              setError('');
              setProfile({ ...profile, avatar: { body: defaultAvatar } });
              setActiveStep(activeStep + 1);
            }}
          >
            {' '}
            Skip{' '}
          </Button>
        )}
      </Container>,
    ];
  };

  const handleProjectsChange = (e, index) => {
    setProjects(
      projects.map((project, i) =>
        i === index ? { ...project, [e.target.id]: e.target.value } : project,
      ),
    );
  };

  const renderCompanyAndProjectsForm = () => {
    return projects.map((project, index) => {
      return (
        <OtherInfoContainer key={index}>
          <TitleAndIcon autoWidth spaceBetween>
            <Container noPadding>
              <TitleAndIcon color={colors.darkBlue}>
                Project {index + 1}
                <Icon
                  onClick={() =>
                    setProjects(
                      projects.map((project, i) =>
                        i === index
                          ? { ...project, isOpen: !project.isOpen }
                          : project,
                      ),
                    )
                  }
                  icon={project.isOpen ? faAngleUp : faAngleDown}
                />
              </TitleAndIcon>
            </Container>
            <Button
              noMargin
              right
              danger
              xsmall
              onClick={() =>
                setProjects(projects.filter((project, i) => i !== index))
              }
            >
              {' '}
              Delete{' '}
            </Button>
          </TitleAndIcon>
          <CollapsableSectionForm show={project.isOpen}>
            <Note noMargin>Company Name*</Note>
            <Input
              full
              marginBottom
              value={project.employer}
              id="employer"
              onChange={e => handleProjectsChange(e, index)}
            />
            <Note noMargin>Project Name*</Note>
            <Input
              full
              marginBottom
              value={project.name}
              id="name"
              onChange={e => handleProjectsChange(e, index)}
            />
            <Note noMargin>Description</Note>
            <ReactQuill
              value={project.description ? project.description : '<p></p>'}
              onChange={value =>
                setProjects(
                  projects.map((project, i) =>
                    i === index ? { ...project, description: value } : project,
                  ),
                )
              }
              modules={Editor.modules}
              formats={Editor.formats}
              theme="snow"
            />
            <Note noMargin>Started At</Note>
            <Input
              type="date"
              full
              marginBottom
              value={project.startedAt}
              id="startedAt"
              onChange={e => handleProjectsChange(e, index)}
            />
            <Note noMargin>Ended At</Note>
            <Input
              type="date"
              full
              disabled={!project.startedAt}
              marginBottom
              value={project.endedAt}
              min={project.startedAt}
              id="endedAt"
              onChange={e => handleProjectsChange(e, index)}
            />
          </CollapsableSectionForm>
        </OtherInfoContainer>
      );
    });
  };

  const handleEducationChange = (e, index) => {
    setEducations(
      educations.map((education, i) =>
        i === index
          ? { ...education, [e.target.id]: e.target.value }
          : education,
      ),
    );
  };

  const renderEducationForm = () => {
    return educations.map((education, index) => {
      return (
        <OtherInfoContainer key={index}>
          <TitleAndIcon autoWidth spaceBetween>
            <Container noPadding>
              <TitleAndIcon color={colors.darkBlue}>
                Qualification {index + 1}
                <Icon
                  onClick={() =>
                    setEducations(
                      educations.map((education, i) =>
                        i === index
                          ? { ...education, isOpen: !education.isOpen }
                          : education,
                      ),
                    )
                  }
                  icon={education.isOpen ? faAngleUp : faAngleDown}
                />
              </TitleAndIcon>
            </Container>
            <Button
              noMargin
              right
              danger
              xsmall
              onClick={() =>
                setEducations(educations.filter((education, i) => i !== index))
              }
            >
              {' '}
              Delete{' '}
            </Button>
          </TitleAndIcon>
          <CollapsableSectionForm show={education.isOpen}>
            <Note noMargin>Degree Name*</Note>
            <Input
              full
              marginBottom
              value={education.degree}
              id="degree"
              onChange={e => handleEducationChange(e, index)}
            />
            <Note noMargin>University*</Note>
            <Input
              full
              marginBottom
              value={education.university}
              id="university"
              onChange={e => handleEducationChange(e, index)}
            />
            <Note noMargin>City</Note>
            <Input
              full
              marginBottom
              value={education.city}
              id="city"
              onChange={e => handleEducationChange(e, index)}
            />
            <Note noMargin>Started At</Note>
            <Input
              type="date"
              full
              marginBottom
              value={education.startedAt}
              id="startedAt"
              onChange={e => handleEducationChange(e, index)}
            />
            <Note noMargin>Ended At</Note>
            <Input
              type="date"
              full
              marginBottom
              value={education.endedAt}
              id="endedAt"
              onChange={e => handleEducationChange(e, index)}
            />
          </CollapsableSectionForm>
        </OtherInfoContainer>
      );
    });
  };

  const showEducationErrors = () => (
    <Note error>
      Please fill all compulsory(*) fields in{' '}
      {educationErrors.map((educationNumber, index) => {
        return `${educationNumber}${
          index === educationErrors.length - 1 ? '.' : ','
        } `;
      })}
    </Note>
  );

  const showProjectErrors = () => (
    <Note error>
      Please fill all compulsory(*) fields in{' '}
      {projectErrors.map((projectNumber, index) => {
        return `${projectNumber}${
          index === projectErrors.length - 1 ? '.' : ','
        } `;
      })}
    </Note>
  );

  const renderOtherInfoForm = () => {
    return (
      <FormFieldContainer>
        <TitleAndIcon>
          Company and Projects
          <Icon
            onClick={() => setProjects([...projects, projectFields])}
            icon={faPlusCircle}
          />
        </TitleAndIcon>
        {renderCompanyAndProjectsForm()}
        <TitleAndIcon>
          Education Details
          <Icon
            onClick={() => setEducations([...educations, educationFields])}
            icon={faPlusCircle}
          />
        </TitleAndIcon>
        {renderEducationForm()}
        <TitleAndIcon>Add Message</TitleAndIcon>
        <ReactQuill
          value={profile.message ? profile.message : '<p></p>'}
          onChange={value => setProfile({ ...profile, message: value })}
          modules={Editor.modules}
          formats={Editor.formats}
          theme="snow"
        />
        {projectErrors.length ? showProjectErrors() : null}
        {educationErrors.length ? showEducationErrors() : null}
      </FormFieldContainer>
    );
  };

  const validatePersonalInfo = () => {
    if (
      profile.name.trim().length &&
      nameRegex.test(profile.name) &&
      profile.email.trim().length &&
      emailRegex.test(profile.email) &&
      profile.city.trim().length &&
      nameRegex.test(profile.city) &&
      profile.country.trim().length
    ) {
      setError('');
      setProfile({
        ...profile,
        name: profile.name.trim(),
        city: profile.city.trim(),
      });
      updateApplication({
        _handler: 'update-application',
        reactApplyFlow: true,
        job: props.jobId,
        name: profile.name.trim(),
        email: profile.email.trim(),
        city: profile.city.trim(),
        country: profile.country.trim(),
        source: source,
      });
      return true;
    } else if (!profile.name.length || !nameRegex.test(profile.name)) {
      setError('Please enter a valid name');
      return false;
    } else if (!emailRegex.test(profile.email)) {
      setError('Please enter valid email address');
      return false;
    } else if (!profile.city.length || !nameRegex.test(profile.city)) {
      setError('Please enter valid city');
      return false;
    } else {
      setError('Please fill all the required fields');
      return false;
    }
  };

  const validateQuickQuestions = () => {
    let validity = quickQuestions.map(question => {
      if (
        question?.isRequired &&
        !['checkbox', 'slider'].includes(question?.mimeType) &&
        !question?.value?.trim()?.length
      ) {
        return false;
      } else if (
        question?.isRequired &&
        question?.mimeType === 'checkbox' &&
        !question?.value?.length
      ) {
        return false;
      } else if (
        question?.isRequired &&
        question?.mimeType === 'radio' &&
        !question?.value
      ) {
        return false;
      }
      return true;
    });
    let questionRequest = quickQuestions.map(question => {
      let score = {
        achieveScore: 0,
        maxScore: 0,
      };
      if (question.mimeType === 'radio' || question.mimeType === 'dropdown') {
        question.field_options.map(option => {
          if (score.maxScore < option.option_score)
            score.maxScore = option.option_score;
          if (option.option_title === question.value)
            score.achieveScore = option.option_score;
        });
      }
      if (question.mimeType === 'slider') {
        score.achieveScore =
          (parseInt(question.value) -
            (question.minRange - question.defaultScore)) *
          ((question.maxScore - question.minScore) /
            (question.maxRange - question.minRange));
        score.maxScore = question.maxScore;
      }
      if (question.mimeType === 'checkbox') {
        question.field_options.map(option => {
          score.maxScore += option.option_score;
          if (question.value.includes(option.option_title))
            score.achieveScore += option.option_score;
        });
      }
      return {
        form: question._id,
        answer: question?.value,
        ...score,
      };
    });
    if (validity.includes(false)) {
      setError('Please answer the above question(s).');
      return false;
    } else {
      updateApplication({
        _handler: 'update-application',
        reactApplyFlow: true,
        job: props.jobId,
        _id: profile.id,
        name: profile.name.trim(),
        email: profile.email.trim(),
        operation: 'questionnaire',
        questionnaire: questionRequest,
        source: source,
      });
      return true;
    }
  };

  const validateCV = () => {
    if (
      (profile.cv && profile.cv.length && profile.linkedIn.length == 0) ||
      (LINK_REGEX.test(profile.linkedIn) &&
        profile.linkedIn.indexOf('linkedin') !== -1)
    ) {
      setError('');
      return true;
    } else if (
      !(
        LINK_REGEX.test(profile.linkedIn) &&
        profile.linkedIn.indexOf('linkedin') !== -1
      )
    ) {
      setError('Please add a valid LinkedIn url');
      return false;
    } else {
      setError('Please upload your cv or add your LinkedIn profile url ');
      return false;
    }
  };

  const validateAvatar = () => {
    if (profile.avatar.body !== defaultAvatar) {
      setError('');
      updateApplication({
        _handler: 'update-application',
        reactApplyFlow: true,
        job: props.jobId,
        _id: profile.id,
        name: profile.name.trim(),
        email: profile.email.trim(),
        operation: 'uploadAvatar',
        avatar: profile.avatar.body,
        type: profile.avatar.type,
        size: profile.avatar.size ? profile.avatar.size : undefined,
        source: source,
      });
      return true;
    } else {
      setError(
        'Please upload or select an avatar from the default avatar list',
      );
      return false;
    }
  };

  const validateOtherOptions = () => {
    let eduErrors = [];
    let projectErrors = [];
    educations.map((education, index) => {
      if (!education.degree || !education.university) {
        eduErrors = [...eduErrors, `Qualification ${index + 1}`];
      }
      return null;
    });
    setEducationError(eduErrors);
    projects.map((project, index) => {
      if (!project.employer || !project.name) {
        projectErrors = [...projectErrors, `Project ${index + 1}`];
      }
      return null;
    });
    setProjectErrors(projectErrors);
    if (!eduErrors.length && !projectErrors.length) {
      updateApplication({
        _handler: 'update-application',
        reactApplyFlow: true,
        job: props.jobId,
        _id: profile.id,
        name: profile.name,
        email: profile.email,
        operation: 'otherInfo',
        educations: educations,
        projects: projects,
        message: profile.message,
        source: source,
      });
      updateApplication({
        _handler: 'update-application',
        reactApplyFlow: true,
        job: props.jobId,
        _id: profile.id,
        name: profile.name.trim(),
        email: profile.email.trim(),
        linkedin:
          LINK_REGEX.test(profile.linkedIn) &&
          profile.linkedIn.indexOf('linkedin') !== -1
            ? profile.linkedIn.indexOf('https') === -1
              ? `https://${profile.linkedIn}`
              : profile.linkedIn
            : '',
        operation: 'uploadFiles',
        files: profile.cv,
        source: source,
      });
      props.submitted();
      setActiveStep(0);
      setShowAvatars(false);
      setEducationError([]);
      setProjectErrors([]);
      setProjects([]);
      setEducations([]);
      setProfile(defaultProfile);
      let questions = JSON.parse(JSON.stringify(quickQuestions));
      questions.map(question => {
        question.value = '';
        return question;
      });
      setQuickQuestions(questions);
    }
    return false;
  };

  const stepWiseDataValid = step => {
    if (step === 0) {
      return validatePersonalInfo();
    } else if (props.quickQuestions.length) {
      if (step === 1) {
        return validateQuickQuestions();
      } else if (step === 2) {
        return validateCV();
      } else if (step === 3) {
        return validateAvatar();
      } else {
        return validateOtherOptions();
      }
    } else if (step === 1) {
      return validateCV();
    } else if (step === 2) {
      return validateAvatar();
    } else {
      return validateOtherOptions();
    }
  };

  return (
    <Modal key={10} style={{ width: '100vw' }} isOpen={props.isOpen}>
      <ModalHeader
        toggle={() => {
          clearEntries();
          props.toggle();
        }}
      >
        {job.name ? `Application for ${job.name}` : 'Application'}
      </ModalHeader>
      <ModalBody>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          onSelect={() => handleOnClickStepper()}
          showNumber={true}
          styleConfig={{
            completedBgColor: '#448ac7',
            activeBgColor: '#285c88',
          }}
        />
        {props.quickQuestions.length
          ? activeStep === 0
            ? renderPersonalInfoForm()
            : activeStep === 1
            ? renderQuickQuestions()
            : activeStep === 2
            ? renderUploadCVForm()
            : activeStep === 3
            ? renderUploadImageForm()
            : renderOtherInfoForm()
          : activeStep === 0
          ? renderPersonalInfoForm()
          : activeStep === 1
          ? renderUploadCVForm()
          : activeStep === 2
          ? renderUploadImageForm()
          : renderOtherInfoForm()}
      </ModalBody>
      <ModalFooter
        style={activeStep !== 0 ? { justifyContent: 'space-between' } : null}
      >
        {activeStep !== 0 ? (
          <Button
            noMargin
            border
            primary
            onClick={() => {
              setError('');
              setActiveStep(activeStep - 1);
            }}
          >
            Back
          </Button>
        ) : null}
        <Button
          noMargin
          border
          primary
          onClick={() => {
            if (stepWiseDataValid(activeStep)) {
              setActiveStep(activeStep + 1);
              setError('');
            }
          }}
          disabled={nextDisable}
        >
          {props.quickQuestions.length
            ? activeStep === 4
              ? 'Submit'
              : 'Save and Next'
            : activeStep === 3
            ? 'Submit'
            : 'Save and Next'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
